import { Controller } from "@hotwired/stimulus";

// source (with hidden overflow check custom added): https://www.stimulus-components.com/docs/stimulus-read-more/

export default class extends Controller {
  static targets = ["content", "button"];
  static values = {
    moreText: String,
    lessText: String,
  };

  connect() {
    this.open = false;
    this.checkOverflow();
  }

  checkOverflow() {
    const isOverflowing =
      this.contentTarget.scrollHeight > this.contentTarget.clientHeight;

    if (this.hasButtonTarget) {
      if (isOverflowing) {
        this.buttonTarget.classList.remove("hidden");
      } else {
        this.buttonTarget.classList.add("hidden");
      }
    }
  }

  toggle(event) {
    this.open === false ? this.show(event) : this.hide(event);
  }

  show(event) {
    this.open = true;
    const target = this.hasButtonTarget ? this.buttonTarget : event.target;
    target.innerHTML = this.lessTextValue;
    this.contentTarget.style.setProperty("--read-more-line-clamp", "'unset'");
  }

  hide(event) {
    this.open = false;
    const target = this.hasButtonTarget ? this.buttonTarget : event.target;
    target.innerHTML = this.moreTextValue;
    this.contentTarget.style.removeProperty("--read-more-line-clamp");
    this.checkOverflow();
  }
}
