// Use case:
// A "Quick select" dropdown to fill a bond as a multiple of weeks of rent

import { Controller } from "@hotwired/stimulus";
import { enter } from "el-transition";

export default class extends Controller {
  static targets = ["rent", "bond"];

  onChange(event) {
    let offsetMultiplier = parseInt(event.target.value);
    event.target.value = "";

    if (!this.rentTarget?.value) {
      return;
    }

    if (!offsetMultiplier) {
      return;
    }

    this.bondTarget.value = (this.rentTarget.value * offsetMultiplier).toFixed(
      2,
    );

    enter(this.bondTarget);
  }
}
